/* eslint-disable */

import React, { useContext, useEffect, useRef } from "react"

import { useLocation } from "@reach/router"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import styled from "styled-components"

import { BackgroundContext, ScreenContext } from "components/Providers"
import blackBG from "images/global/Black-Background-Tile-2x.webp"
import whiteBG from "images/global/White-Background-Tile-2x.webp"
import { clamp, vwToPx } from "utils/functions"
import { pageReady } from "utils/pageReady"
import useMedia from "utils/useMedia"

import { useIsSmooth } from "./Scroll"
import loader from "utils/Loader"

const RENDER_BUFFER = 100

const boundsOnscreen = (top: number, bottom: number) => {
  return top < window.innerHeight + RENDER_BUFFER && bottom > -RENDER_BUFFER
}

const getDesiredCanvasHeight = () => {
  const windowHeight = Math.max(window.innerHeight, window.outerHeight)
  // we don't want the canvas to resize when scrolling on mobile
  // sometimes on mobile outerHeight matches innerHeight, so in that case we use screen height instead
  // overestimating is fine
  return screen.height < windowHeight + 200
    ? Math.max(windowHeight, screen.height)
    : windowHeight
}

const blobOffscreen = (offset: number, blobHeight: number) => {
  // if blobHeight is negative, compensate for it
  const compensation = blobHeight < 0 ? Math.abs(blobHeight) : 0

  // determine if the DOMRect is on the screen
  return !boundsOnscreen(offset - compensation, Math.abs(blobHeight))
}

export type BackgroundKiller = () => void

interface Section {
  element: HTMLElement
  /**
   * distance from the top of the element to the top of the screen
   */
  topOffset: number
  /**
   * height of the element
   */
  height: number
}

type Props = {
  position?: string
}

export default function BackgroundCanvas({ position }: Props) {
  const MAX_HEIGHT = useMedia(300, 300, 200, 75)
  const sections: { [key: string]: Section } = {}
  const canvasEl = useRef<HTMLCanvasElement>(null)
  let canvas: HTMLCanvasElement | null = null
  let darkBackground: CanvasPattern | null | undefined = null
  let lightBackground: CanvasPattern | null | undefined = null
  let idCounter = 0
  let blobHeight = 0
  let ctx: CanvasRenderingContext2D | null = null
  let lastScrollTop: number | undefined
  let totalOffset = 0

  const usingWave = useIsSmooth()

  const location = useLocation()
  const { mobile } = useContext(ScreenContext)
  const { setAddBackgroundSection } = useContext(BackgroundContext)

  const addBackgroundSection = (element?: HTMLElement) => {
    if (element && typeof element.getBoundingClientRect === "function") {
      idCounter++

      sections[idCounter] = {
        element,
        height: element.clientHeight,
        topOffset: element.getBoundingClientRect().top,
      }
    }

    return () => {}
  }

  const updateBlobs = () => {
    updateScroll()

    const smoother = ScrollSmoother.get()
    if (!smoother) return
    // prevent errored values
    if (Math.abs(smoother.getVelocity()) < 10000)
      blobHeight -= smoother.getVelocity() * 0.01
    blobHeight *= 0.9
    blobHeight = Math.round(blobHeight)
    blobHeight = clamp(blobHeight, -MAX_HEIGHT, MAX_HEIGHT)

    if (!ctx) return
    if (!canvas) return

    darkBackground?.setTransform(
      new DOMMatrix().translate(0, -smoother.scrollTop()).scale(0.5)
    )
    lightBackground?.setTransform(
      new DOMMatrix().translate(0, -smoother.scrollTop()).scale(0.75)
    )

    // iterate through sections
    for (const key in sections) {
      const section = sections[key]

      const isDark = section.element.getAttribute("data-is-dark") === "true"
      const hasTopBlob = !(
        section.element.getAttribute("data-no-top") === "true"
      )
      const hasBottomBlob = !(
        section.element.getAttribute("data-no-bottom") === "true"
      )
      const localHasTopBlob = blobOffscreen(section.topOffset, blobHeight)
        ? false
        : hasTopBlob
      const localHasBottomBlob = blobOffscreen(
        section.topOffset + section.height,
        blobHeight
      )
        ? false
        : hasBottomBlob
      let offset = section.topOffset
      const top = ScrollSmoother.get().scrollTop()
      if (position === "relative" && top < window.innerHeight) {
        offset += top
      }
      const wrapperHeight = section.height

      // constructs a wave path using the velocity
      // not a huge fan of the concatting but it's worth it for readability
      const newPath = `M0,${offset} ${
        localHasTopBlob
          ? // top left curve
            `C${vwToPx(35)},${offset} ` +
            `${vwToPx(35)},${blobHeight + offset} ` +
            `${vwToPx(50)},${blobHeight + offset} ` +
            // top right curve
            `C${vwToPx(65)},${blobHeight + offset} ` +
            `${vwToPx(65)},${offset} ` +
            `${vwToPx(100)},${offset} `
          : // top side (straight line)
            `L${vwToPx(100)},${offset} `
        // right side
      }L${vwToPx(100)},${wrapperHeight + offset} ${
        localHasBottomBlob
          ? // bottom right curve
            `C${vwToPx(65)},${wrapperHeight + offset} ` +
            `${vwToPx(65)},${wrapperHeight + blobHeight + offset} ` +
            `${vwToPx(50)},${wrapperHeight + blobHeight + offset} ` +
            // bottom left curve
            `C${vwToPx(35)},${wrapperHeight + blobHeight + offset} ` +
            `${vwToPx(35)},${wrapperHeight + offset} ` +
            `0,${wrapperHeight + offset} `
          : // bottom side (straight line)
            `L0,${wrapperHeight + offset} `
      }Z`

      // check if the wrapper is on the screen
      if (
        boundsOnscreen(
          section.topOffset + Math.min(0, blobHeight),
          section.topOffset + section.height + Math.max(0, blobHeight)
        )
      ) {
        // fill section on canvas
        ctx.fillStyle = isDark
          ? darkBackground ?? "black"
          : lightBackground ?? "white"
        ctx.fill(new Path2D(newPath))
      }
    }
  }

  const updateSizeAndPositions = () => {
    // update client rects for each section
    for (const key in sections) {
      const section = sections[key]
      section.topOffset = section.element.getBoundingClientRect().top
      section.height = section.element.clientHeight
    }

    // check if canvas size has changed
    if (canvas && canvas?.width !== canvas.clientWidth) {
      canvas.width = canvas.clientWidth
      requestAnimationFrame(updateSizeAndPositions)
    }

    if (canvas) {
      const height = mobile
        ? getDesiredCanvasHeight()
        : canvas.clientHeight ?? 0
      if (Math.abs((canvas?.height ?? Infinity) - height) > 1) {
        canvas.height = height
        canvas.style.height = mobile ? `${height}px` : "100vh"
        requestAnimationFrame(updateSizeAndPositions)
      }
    }

    requestAnimationFrame(() => updateBlobs())
  }

  const init = () => {
    lastScrollTop = undefined
    updateScroll()
    for (const key in sections) {
      delete sections[key]
    }
    canvas = canvasEl.current

    if (canvas) {
      canvas.width = canvas.clientWidth
      const height = mobile ? getDesiredCanvasHeight() : canvas.clientHeight

      canvas.height = height
      canvas.style.height = mobile ? `${height}px` : "100vh"
      ctx = canvas.getContext("2d")
    }

    const darkImage = new Image()
    darkImage.src = blackBG
    darkImage.onload = () => {
      darkBackground = ctx?.createPattern(darkImage, "repeat")
      requestAnimationFrame(updateBlobs)
    }
    const lightImage = new Image()
    lightImage.src = whiteBG
    lightImage.onload = () => {
      lightBackground = ctx?.createPattern(lightImage, "repeat")
      requestAnimationFrame(updateBlobs)
    }

    // when any images on the page are loaded, update the sizes and positions
    const images = Array.from(document.querySelectorAll("img"))
    for (const image of images) {
      image.addEventListener("load", () => {
        requestAnimationFrame(updateSizeAndPositions)
      })
    }

    setAddBackgroundSection(() => undefined)
    setTimeout(() => {
      setAddBackgroundSection(() => addBackgroundSection)
    }, 0)

    requestAnimationFrame(updateBlobs)
    pageReady()
      .then(() => requestAnimationFrame(updateBlobs))
      .catch(console.error)
  }

  const movementFactor = usingWave ? 1 : 100
  const updateScroll = () => {
    if (lastScrollTop) {
      const newScrollTop = ScrollSmoother.get()?.scrollTop() ?? 0
      const delta = newScrollTop - lastScrollTop
      lastScrollTop = newScrollTop

      // if the delta is too big, that usually indicates a scroll event directly after a resize
      // has to be ignored or the blobs will be drawn incorrectly
      if (Math.abs(delta) > 100) {
        requestAnimationFrame(updateSizeAndPositions)
        return
      }

      totalOffset += delta

      for (const key in sections) {
        const section = sections[key]
        section.topOffset -= totalOffset / movementFactor
      }
      totalOffset -= totalOffset / movementFactor
      if (Math.abs(totalOffset) > 1) requestAnimationFrame(updateBlobs)
    } else {
      lastScrollTop = ScrollSmoother.get()?.scrollTop() ?? 0
      totalOffset = 0
    }
  }

  useEffect(() => {
    if (!usingWave) return

    let previousWidth = window.innerWidth
    const mobileResize = () => {
      if (mobile)
        if (previousWidth !== window.innerWidth) {
          previousWidth = window.innerWidth
          requestAnimationFrame(init)
        }
    }

    init()
    addEventListener("resize", updateSizeAndPositions)
    addEventListener("smoothScroll", updateBlobs)
    addEventListener("resize", mobileResize)
    return () => {
      removeEventListener("smoothScroll", updateBlobs)
      removeEventListener("resize", updateSizeAndPositions)
      removeEventListener("resize", mobileResize)
    }
  }, [usingWave, mobile])

  useEffect(() => {
    init()

    loader.addEventListener("anyEnd", init)
    return () => {
      loader.removeEventListener("anyEnd", init)
    }
  }, [location.pathname])

  return <Canvas ref={canvasEl} position={position} />
}

const Canvas = styled.canvas<{ position?: string }>`
  position: ${props => props.position ?? "fixed"};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`
