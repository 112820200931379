const easing = {
  main: `ease-in-out`,
  gsapMain: "power1.inOut",
  gsapMainIn: "power1.in",
  gsapMainOut: "power1.out",

  transition: "cubic-bezier(0.79, 0.01, 0.58, 1.05)",
  gsapTransition: "M0,0 C0.79,0.01 0.58,1.05 1,1",
}

export default easing
