import React from "react"

import styled from "styled-components"

import media from "styles/media"
import text from "styles/text"
import links from "utils/links"
import UniversalLink from "utils/Loader/UniversalLink"

import ArrowLink from "./ArrowLink"

type SocialProps = {
  forwardRef?: React.RefObject<HTMLDivElement>
  hideEmail?: boolean
  hideLegal?: boolean
  darkText?: boolean
}

export default function Socials({
  forwardRef = undefined,
  hideEmail: showEmail = false,
  darkText = false,
  hideLegal = false,
}: SocialProps) {
  return (
    <Links ref={forwardRef}>
      {!showEmail && (
        <a href={links.email} target="_blank" rel="noreferrer">
          <ArrowLink darkText={darkText}>Email</ArrowLink>
        </a>
      )}
      <a href={links.linkedin} target="_blank" rel="noreferrer">
        <ArrowLink darkText={darkText}>Linkedin</ArrowLink>
      </a>
      {!hideLegal && (
        <>
          <UniversalLink to="/terms" transition="generic">
            <ArrowLink darkText={darkText}>Terms</ArrowLink>
          </UniversalLink>
          <UniversalLink to="/privacy" transition="generic">
            <ArrowLink darkText={darkText}>Privacy Policy</ArrowLink>
          </UniversalLink>
        </>
      )}
    </Links>
  )
}

const Links = styled.div`
  ${text.sub3};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-area: social;

  gap: 25px;
  ${media.desktop} {
    gap: 1.736vw;
  }
  ${media.tablet} {
    gap: 2.441vw;
  }
  ${media.mobile} {
    gap: 6.67vw;
  }
`
