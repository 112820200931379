import React from "react"

// eslint-disable-next-line import/no-cycle
import Background, { BackgroundContext } from "./Background"
import Nav, { NavContext } from "./Nav"
import Screen, { ScreenContext } from "./Screen"

interface ProvidersProps {
  children: React.ReactNode
}

export default function Providers({ children }: ProvidersProps) {
  return (
    <Screen>
      <Background>
        <Nav>{children}</Nav>
      </Background>
    </Screen>
  )
}

export { ScreenContext, BackgroundContext, NavContext }
