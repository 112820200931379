import React, { useCallback, useEffect, useRef } from "react"

import gsap from "gsap"
import styled, { keyframes } from "styled-components"

import colors from "styles/colors"
import media from "styles/media"
import { strokeText } from "styles/text"
import useCanHover from "utils/canHover"
import { pageReady } from "utils/pageReady"

type NavItemProps = {
  children: string
  navOnScreen?: boolean
  color: string
  isActive: boolean
}

export default function NavItem({
  children: textValue,
  navOnScreen = false,
  color,
  isActive,
}: NavItemProps) {
  const hoverEl = useRef<HTMLDivElement>(null)
  const item = useRef<HTMLDivElement>(null)
  const blinky = useRef<HTMLDivElement>(null)

  const textTimeline = useRef<gsap.core.Timeline | null>(null)

  const hover = useCanHover()

  // initial animation into screen
  useEffect(() => {
    pageReady(() => {
      if (navOnScreen) {
        textTimeline.current?.kill()
        textTimeline.current = gsap.timeline({})

        // make item width static
        if (item.current && hoverEl.current) {
          hoverEl.current.style.removeProperty("width")
          item.current.innerText = textValue
          hoverEl.current.style.width = `${hoverEl.current.offsetWidth}px`
          item.current.innerText = ""
        }

        gsap.fromTo(item.current, {
            text: {
              value: "",
            },
          },
          {
            text: {
              value: textValue,
            },
            delay: 0.5,
            onStart: () => {
              if (blinky.current) {
                blinky.current.style.opacity = "1"
                blinky.current.style.animation = "unset"
              }
            },
            onComplete: () => {
              if (blinky.current) {
                blinky.current.style.removeProperty("opacity")
                blinky.current.style.removeProperty("animation")
              }
            },
          })
      }
    }).catch(console.error)
  }, [navOnScreen, textValue])

  const handleMouseEnter = useCallback(() => {
    if (hover) {
      // animation on hover
      textTimeline.current?.kill()
      textTimeline.current = gsap.timeline({})
      textTimeline.current.fromTo(item.current, {
          text: {
            value: textValue.charAt(0),
            speed: 1,
          },
        },
        {
          text: {
            value: textValue,
          },
          onStart: () => {
            hoverEl.current?.removeEventListener("mouseenter", handleMouseEnter)
            if (blinky.current) {
              blinky.current.style.opacity = "1"
              blinky.current.style.animation = "unset"
            }
          },
          onComplete: () => {
            hoverEl.current?.addEventListener("mouseenter", handleMouseEnter)
            if (blinky.current) {
              blinky.current.style.removeProperty("opacity")
              blinky.current.style.removeProperty("animation")
            }
          },
        })
    }
  }, [hover, textValue])

  // mouse hover logic
  useEffect(() => {
    const hoverElCurrent = hoverEl.current
    if (hoverElCurrent) {
      hoverElCurrent.addEventListener("mouseenter", handleMouseEnter)
      return () => {
        hoverElCurrent.removeEventListener("mouseenter", handleMouseEnter)
      }
    }
  }, [handleMouseEnter])

  return (
    <Wrapper ref={hoverEl}>
      <Item ref={item} isActive={isActive} color={color}>
        {textValue}
      </Item>
      <Blinky ref={blinky} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  width: 100%;
  position: relative;
`

const Item = styled.div<{ isActive: boolean; color: string }>`
  ${({ isActive }) => !isActive && strokeText};
  color: ${({ color }) => color};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  padding-right: 1%;
`

const blinks = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const Blinky = styled.div`
  border-bottom: 10px solid ${colors.mainGreen};
  display: inline-block;
  width: 60px;
  height: 80px;
  transform: translateY(10px);
  z-index: 1;
  opacity: 0;

  ${Wrapper}:hover & {
    animation: ${blinks} 0.5s steps(1) infinite;
  }

  ${media.mobile} {
    width: 8vw;
  }
`
